* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Raleway", sans-serif;

}

input,
button {
  appearance: none;
  background: none;
  border: none;
  outline: none;
}

.home,
.integrations {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

/* .App {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #53565a;
} */
